import React, { useEffect } from "react"
import { navigate } from "gatsby"
import LoadingScreen from "../../components/LoadingScreen"

// OBSOLTE: this page is not used anymore
const SubscribePage = () => {
  useEffect(() => {
    navigate("/auth/sign-up", { replace: true })
  }, [])

  return <LoadingScreen />
}

export default SubscribePage
